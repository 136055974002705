//store/modules/auth.js

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {

    async verifyVoucher({rootGetters}, form) {
        console.log(rootGetters['userToken'],"TOKEN")
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('scanner/voucher/use',form, headers)
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
