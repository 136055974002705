import axios from 'axios';
import MainApp from '@/router'

const state = {
    user: null,
    token: null,
    outlet: {}
};

const getters = {
    isAuthenticated: function(){
        const account = getters.getUser

        if(account!=null || account!=undefined){
            return true
        }else{
            return false
        }
    },  

    getUser: state => state.user,

    userToken: state => state.token,

    getOutlet: state => state.outlet
};

const actions = {
    checkAuth({rootGetters},url = null){
        const user = rootGetters.getUser
        if(!user){
            if(url.path!='/login'){
                MainApp.push("/login")
            }
        }
    },

    async Login({},form) {
        const response = await axios.post('scanner/login', form)
        return response
    },

    async updateProfile({}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: getters.userToken ? `Bearer ${ getters.userToken }` : '',
            }
        }

        const response = await axios.post('profile-update',form, headers)
        return response
    },

    async getProfile({}, form) {
        const headers =  {
            Authorization: getters.userToken ? `Bearer ${ getters.userToken }` : '',
        }


        const response = await axios.get('user-detail',{ params: {id: form.id}, headers: headers})
        return response
    },

    setLogin({commit}, data){
        commit('setuserdata',data)
    },

    async Logout({commit}){
        commit('logout')
        return true;
    },

    setUserPhone({commit}, phone){
        state.phone = phone
    }
};

const mutations = {
    logout(state){
        state.user      = null
        state.token     = null
    },
    setuserdata(state,data){
        state.user      = data.user
        state.token     = data.token
        state.outlet    = data.user.outlet_account.outlet
    }
};

export default {
  state,
  getters,
  actions,
  mutations
};
