

import { createStore } from 'vuex'

import createPersistedState from "vuex-persistedstate";
import voucher from './modules/voucher';
import outlet from './modules/outlet';
import auth from './modules/auth';


export default createStore({
  modules: {
    voucher,
    outlet,
    auth
  },
  plugins: [createPersistedState()]
});
